import { IConfig } from './models'

// YO! nginx image runs this through env
const config: IConfig = {
  env: 'production',
  graphqlUri: '$HOST/hasura/v1/graphql',
  graphqlWsUri: '$WEBSOCKET_HOST/hasura/v1/graphql',
  backendUri: '$HOST/backend',
  receiverUri: 'https://faro-collector-prod-us-central-0.grafana.net/collect/10dd6098e7fe22ac0e9721f639f15a64'
}

export const __cache_version = 1

try {
  // @ts-ignore
  window.__CONFIG__ = config
} catch (e) {}

export default config
